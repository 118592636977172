/*eslint-disable*/
import Cookies from 'js-cookie';
export default {
  init() {
    // JavaScript to be fired on all pages

    (function () {
      var doc = document.documentElement;
      var w = window;
      var prevScroll = w.scrollY || doc.scrollTop;
      var curScroll;
      var direction = 0;
      var prevDirection = 0;
      var header = document.getElementById('header');

      var checkScroll = function () {
        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
          //scrolled up
          direction = 2;
        } else if (curScroll < prevScroll) {
          //scrolled down
          direction = 1;
        }
        if (direction !== prevDirection) {
          toggleHeader(direction, curScroll);
        }
        prevScroll = curScroll;
      };

      var toggleHeader = function (direction, curScroll) {
        if (direction === 2 && curScroll > 52) {
          //replace 52 with the height of your header in px

          header.classList.add('hide');
          prevDirection = direction;
        } else if (direction === 1) {
          header.classList.remove('hide');
          prevDirection = direction;
        }
      };

      window.addEventListener('scroll', checkScroll);
    })();

    $("a[href='#top']").click(function () {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      return false;
    });

    let mybutton = document.getElementById('top');
    function scrollFunction() {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = 'block';
      } else {
        mybutton.style.display = 'none';
      }
    }

    window.onscroll = function () {
      scrollFunction();
    };

    jQuery(document).on('click', '#accept-cookie', function (e) {
      e.preventDefault();

      Cookies.set('accepted-cookie', 1, {
        expires: 365,
      });

      jQuery('.cookie-banner').remove();
    });

    $('.banner__mobile--hamburger').click(function () {
      $(this).toggleClass('menu-toggle');
      $('body').toggleClass('overflow-body');
      $('.banner__mobile').toggle("'slide', {direction: 'right' }, 800");
    });

    $('.search-form-trigger').click(function () {
      $('.search-form-header').slideToggle('medium');
      setTimeout(function () {
        $('#searchform #s').focus();
      }, 500);
    });

    $(document).on('click', function (event) {
      if (!($(event.target).closest('.header-wrapper').length > 0)) {
        $('.search-form-header').slideUp('medium', function () {
          $(this).css('display', 'none');
        });
        setTimeout(function () {
          $('.search-form-header')
            .find('input')
            .each((i, el) => {
              $(el).val('');
            });
        }, 500);
      }
    });

    $('li.menu-item-has-children').each(function () {
      $(this).append(`<span class="sub-menu--toggle"><svg xmlns="http://www.w3.org/2000/svg" fill="none"><path d="M0.5 0.5L5.5 4.5L10.5 0.5" stroke="#CCCCCC"/></svg></span>`);
      $(this)
        .find('.sub-menu--toggle')
        .click(function () {
          $(this).parent().find('.sub-menu').slideToggle('medium');
          $(this).parent().find('.sub-menu--toggle').toggleClass('arrow-opened');
        });
    });

    $('.comment-form-comment').append(`<p class="max-length"></p>`);
    $('#comment').attr('maxlength', 500);

    $('#comment').keyup(function () {
      var max = 500;
      var len = $(this).val().length;
      if (len >= max) {
        $('.max-length').text('Ju keni arritur limitin');
      } else {
        var char = max - len;
        $('.max-length').text(char + ' karaktere të mbetura');
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
