/* eslint-disable */
import Swiper, { Navigation } from 'swiper';
export default {
  init() {
    const axios = require('axios');
    const API_CLIENT = `${BASE_URL}wp-json/wp/v2/posts`;
    const LANG = window.CURRENT_LANG;
    const MOUNT_POINT = document.getElementById('ajax-blog');
    const NO_FOUND_MSG = document.getElementById('notfoundtext-blog');
    const LOADER = document.getElementById('loader-blog');
    const LOAD_MORE = document.getElementById('ajax-blog-loadmore');
    const ALL_LOADED = document.getElementById('allloaded-blog');
    let BLOG_LIST = [];
    let settings = {
      per_page: 12,
      page: 1,
    };

    let trimString = function (string, length) {
      return string.length > length ? string.substring(0, length) + '...' : string;
    };

    function renderArticle(client) {
      let blogHtml = '';
      client.forEach((categoryPost) => {
        BLOG_LIST.push(categoryPost);
        blogHtml += `
        <div class="cat-item-row">
          <a class="title desktop-title" href="${categoryPost.link}">${trimString(categoryPost.title.rendered, 85)}</a>
          <div class="item-wrapper">
          <a href="${categoryPost.link}">
           <img loading="lazy" src="${categoryPost._embedded['wp:featuredmedia'][0].source_url}" alt="${categoryPost._embedded['wp:featuredmedia'][0].alt_text}" />
           </a>
           <a class="title mobile-title" href="${categoryPost.link}">${trimString(categoryPost.title.rendered, 85)}</a>
            <div class="content">
              <div class="author-details-category">
                <a href="@authorurl">${categoryPost._embedded.author[0].name}</a>
                <span>${categoryPost.formatted_date}</span>
              </div>
             ${trimString(categoryPost.excerpt.rendered, 325)}
             <div>
              <a class="permalink-row" href="${categoryPost.link}">Lexo Artikullin
                <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.293 0.293031C17.4805 0.10556 17.7348 0.000244141 18 0.000244141C18.2652 0.000244141 18.5195 0.10556 18.707 0.293031L25.707 7.29303C25.8945 7.48056 25.9998 7.73487 25.9998 8.00003C25.9998 8.26519 25.8945 8.5195 25.707 8.70703L18.707 15.707C18.5184 15.8892 18.2658 15.99 18.0036 15.9877C17.7414 15.9854 17.4906 15.8803 17.3052 15.6948C17.1198 15.5094 17.0146 15.2586 17.0123 14.9964C17.01 14.7342 17.1108 14.4816 17.293 14.293L22.586 9.00003H1C0.734784 9.00003 0.48043 8.89467 0.292893 8.70714C0.105357 8.5196 0 8.26525 0 8.00003C0 7.73481 0.105357 7.48046 0.292893 7.29292C0.48043 7.10539 0.734784 7.00003 1 7.00003H22.586L17.293 1.70703C17.1055 1.5195 17.0002 1.26519 17.0002 1.00003C17.0002 0.734866 17.1055 0.480558 17.293 0.293031Z" fill="#C4C4C4"/>
                </svg>
              </a>
              </div>
            </div>
          </div>
        </div>
        `;
      });
      MOUNT_POINT.innerHTML += blogHtml;
      LOADER.style.display = 'none';
    }
    function fetchBlog(page) {
      LOADER.style.display = 'block';
      let data = {};
      //Current cat from php var
      if (typeof CURRENT_POST_CAT !== 'undefined') {
        data['categories'] = CURRENT_POST_CAT;
      }
      data['per_page'] = settings.per_page;
      data['page'] = page;
      //Excluded from php array
      data['exclude'] = excluded_blog_id;
      data['_embed'] = 1;
      data['lang'] = LANG;

      axios
        .get(API_CLIENT, {
          params: data,
        })
        .then((response) => {
          var totalPages = response.headers && response.headers['x-wp-totalpages'];
          if (page == totalPages) {
            LOAD_MORE.style.display = 'none';
            ALL_LOADED.style.display = 'block';
          } else {
            LOAD_MORE.style.display = 'block';
          }
          if (totalPages < 1) {
            LOADER.style.display = 'none';
          }
          if (response.data.length == 0) {
            NO_FOUND_MSG.style.display = 'block';
          } else {
            NO_FOUND_MSG.style.display = 'none';
          }
          renderArticle(response.data);
        })
        .catch((e) => {
          LOADER.style.display = 'none';
          console.log(e);
        });
    }
    if (LOAD_MORE) {
      LOAD_MORE.addEventListener('click', (event) => {
        fetchBlog(settings.page);
        settings.page++;
      });
    }

    var catSwiper = new Swiper('.cat-featured-swiper', {
      modules: [Navigation],
      autoHeight: true,
      slidesPerView: 1,
      loop: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
